import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';

import { OutputService } from 'src/app/data/services/output.service';
import { ConfigService } from 'src/app/data/services/config.service';
import { OnboardingApiService } from 'src/app/data/services/onboarding-api.service';
import { A2BAssociationID, Config, Page } from 'src/app/data/models/config';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
  providers: [DatePipe, TitleCasePipe],
  
})
export class SuccessComponent implements OnInit {
  form: FormGroup;
  isAPISuccessResponse: boolean;
  errorMsgFromAPI: string;
  isApiTriggered: boolean;
  state: any;
  isPasswordHidden: boolean;
  isConfirmPasswordHidden: boolean;
  isITMEnabled: boolean;
  isOwnerOpEnabled: boolean;
  isMercerEnabled: boolean;
  vehicleInfoDetails: string;
  currentDate: Date = new Date();
  renewsOn: string;
  accountNumber: string;
  coverageTitle: string;
  emailId: string;
  isEFSEnabled: boolean;
  isORDPActive: boolean = false;
  isAttributionID:boolean;
  isBennettEnabled: boolean;
  oemsFaqUrl: string;
  oem?: string;
  newServicePlanIsActive: boolean;
  
  constructor(
    private onboardingAPI: OnboardingApiService,
    private outputService: OutputService,
    public configService: ConfigService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private titleCasePipe: TitleCasePipe
  ) {}

  ngOnInit() {
    this.state = this.outputService.currentOutputState;
    this.isApiTriggered = false;
    this.isAPISuccessResponse = false;
    this.errorMsgFromAPI = '';
    this.isPasswordHidden = true;
    this.isConfirmPasswordHidden = true;
    this.newServicePlanIsActive = this.configService.lastSelectedCoveragePage === 'coverage/trucker-service-plan';

    if(!this.newServicePlanIsActive){
      this.vehicleInfoDetails =
      this.outputService.currentVehicleState.year +
      ' ' +
      this.titleCasePipe.transform(this.state.oem) +
      ' ' +
      this.outputService.currentVehicleState.model;
    }
    
    this.currentDate.setDate(this.currentDate.getDate() + 364);
    this.renewsOn = this.datePipe.transform(this.currentDate, 'MM/d/y');
    this.accountNumber = this.outputService.currentOutputState.accountNumber;
    this.coverageTitle = this.outputService.currentOutputState.servicePlan?.title;
    this.isORDPActive = this.outputService.signUpVal === "YES";

    this.emailId = this.state?.contacts?.[0].email || '';
    this.form = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required],
    }, { validator: this.mustMatch });
    this.isITMEnabled = this.configService.isITMVendor();
    this.isOwnerOpEnabled = this.configService.config.vendorId === "owner-op";
    this.isMercerEnabled = this.configService.config.vendorId === "mercer";
    const environmentName = (environment.envName).replace(/\./g, '').toLowerCase();
    if (environment.useInspectlet && this.configService.config.inspectLetWid?.[environmentName] && !!this.accountNumber) {
      (window as any).__insp?.push(['tagSession', { accountNumber: this.accountNumber }]);
    }
    this.isEFSEnabled = this.config.contractPaymentMethod || false;
    if (this.state.attributionid == A2BAssociationID.AtoB || this.state.attributionid == A2BAssociationID.uberFreightCarrierCard) {
      this.isAttributionID = false;
    } else {
      this.isAttributionID = true;
    }
    this.isBennettEnabled = this.configService.isBennettVendor();
    this.isComdataReferral();
    this.oemSpecificHowToTurnOnYourITM();
  }

  redirectToUrl(): void {
    window.location.href = this.currentScreen.redirectTo;
  }

  redirectToNewPortal(): void{
    window.location.href = this.configService.config.newCustomerPortalUrl;
  }

  close(): void {
    this.isApiTriggered = false;
  }

  mustMatch(control: AbstractControl) : void{
    const password = control.get("password");
    const confirm = control.get("confirmPassword");
        if (confirm.value !== password.value) {
          confirm.setErrors({ noMatch: true });
        } else {
          confirm.setErrors(null);
        }
  }

  toggleConfirmPassword() {
    this.isConfirmPasswordHidden = !this.isConfirmPasswordHidden;
  }
  togglePassword() {
    this.isPasswordHidden = !this.isPasswordHidden;
  }

  async callSignupAssociatePortalAPI() {
    const controls = this.form.controls;

    if(!this.state.accountNumber || !this.emailId) {
      console.error('Invalid email or accountNumber');
      return;
    }
    const credentials = {
      "accountNumber": this.state.accountNumber,
      "emailId": this.emailId,
      "password": controls.password.value
    }

    if(this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    try {
      const resp = await this.onboardingAPI.signupAssociatedPortal(credentials);
      if (resp?.ResponseCode === 20010) {
        this.isAPISuccessResponse = true;
      }
    } catch (error) {
      this.errorMsgFromAPI = error.error.StatusMessage as string;
    } finally {
      this.isApiTriggered = true;
    }
  }
  get config(): Config {
    return this.configService.config;
  }

  get currentScreen(): Page {
    return this.configService.currentPage;
  }

  isComdataReferral() {
    if (this.isEFSEnabled || this.isITMEnabled) {
      this.isAttributionID = false;
    }
    if (this.isMercerEnabled) {
      this.isAttributionID = true;
    }
  }

  oemSpecificHowToTurnOnYourITM() {
    if (this.isITMEnabled) {
      this.oem = this.outputService?.currentOutputState?.oem;
      if (this.oem) {
        this.oemsFaqUrl = this.config?.faqOemsUrl[this.oem];
      }
    }
  }

  next(): void {
    /*if (this.bypassOption.invalid) return 
    let bypassVal = this.bypassOption.value; 
    if(bypassVal == 1){
      this.addBypassOption();
    }else if(bypassVal==0){
        this.declineBypassOption();
    }*/
    const outputState = this.outputService.currentOutputState;
    //outputState.addByPassval=this.bypassOption.value;
    this.outputService.updateState(outputState);
    this.configService.next();
    //this.outputService.nextPage("vehicle/type");
  }

  
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-blank-loading-page',
  templateUrl: './blank-loading-page.component.html',
  styleUrls: ['./blank-loading-page.component.scss']
})
export class BlankLoadingPageComponent {
  title = "loading";
}

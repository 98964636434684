export function getSelectedIndex(
  notified,
  PageGroups,
  isOwnerOp,
  isITMEnabled,
  isCanadianCoverage,
  isFlowB
) {
  const defaultValue = {
    previousStepIndex: 1,
    nextStepIndex: 0,
  };
  if (isOwnerOp) {
    if (
      notified === "contact/info"
    ) {
        return {
          previousStepIndex: 1,
          nextStepIndex: 0,
        };
    }

    if (notified === "coverage/service-plan" || PageGroups.ownerop.coverage.includes(notified) ) {
      return {
        previousStepIndex: 0,
        nextStepIndex: 1,
      };
    }

    if (
      notified === "vehicle/type" ||
      PageGroups.ownerop.vehicles.includes(notified)
    ) {
      return {
        previousStepIndex: 1,
        nextStepIndex: 2,
      };
    }

    if (PageGroups.ownerop.extras.includes(notified)) {
      if(isFlowB){
        return {
          previousStepIndex: 1,
          nextStepIndex: 2,
        };
      }
      else {
        return {
          previousStepIndex: 2,
          nextStepIndex: 3,
        };
      }
    }

    if (PageGroups.ownerop.payment.includes(notified)) {
      if(isFlowB){
        return {
          previousStepIndex: 2,
          nextStepIndex: 3,
        };
      }
      else {
        return {
          previousStepIndex: 3,
          nextStepIndex: 4,
        };
      }
    }
  }
  if (isITMEnabled) {
    if (
      notified === "coverage/service-plan" ||
      PageGroups.itm.coverage.includes(notified)
    ) {
      if (!isCanadianCoverage) {
        return {
          previousStepIndex: 1,
          nextStepIndex: 0,
        };
      }
    }

    if (notified === "contact/info") {
      if (!isCanadianCoverage) {
        return {
          previousStepIndex: 0,
          nextStepIndex: 1,
        };
      }
    }

    if (notified === "vehicle/device-id") {
      if (isCanadianCoverage) {
        // execute below block only for canadian coverage;
        return {
          previousStepIndex: 0,
          nextStepIndex: 1,
        };
      } else {
        return {
          previousStepIndex: 1,
          nextStepIndex: 2,
        };
      }
    }

    if (
      notified === "vehicle/info" ||
      PageGroups.itm.vehicles.includes(notified)
    ) {
      return {
        previousStepIndex: 2,
        nextStepIndex: 3,
      };
    }
  }

  if(notified === "payment/summary"){
    return {
        previousStepIndex: 3,
        nextStepIndex: 4,
      };
  }

  return defaultValue;
}

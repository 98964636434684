import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { ConfigService } from '../services/config.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigResolver implements Resolve<any> {

  constructor(
    private configService: ConfigService,
    private router: Router
  ) { }

  async resolve(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Promise<void> {
    const vendorID = route.paramMap.get('vendorId')
    const config = await this.configService.getConfigFromServer(vendorID)

    if (config) return

    this.router.navigate(["support/customer-support"], {replaceUrl: true})
  }
}

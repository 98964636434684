import { Component, Output, EventEmitter, Input, OnInit  } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfigService } from 'src/app/data/services/config.service';
import { OutputService } from 'src/app/data/services/output.service';
import { Config } from '../../../../../src/app/data/models/config'

@Component({
  selector: 'app-finish-vehicles',
  templateUrl: './finish-vehicles.component.html',
  styleUrls: ['./finish-vehicles.component.scss']
})
export class FinishVehiclesComponent implements OnInit {
  velichesLength:number = 0;
  @Input()
  customTitle: string;
  @Output() handleFinishVehicle: EventEmitter<any> = new EventEmitter();
  @Output() handleRemoveVehicle: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialog: MatDialog,
    private configService: ConfigService,
    private outputService:OutputService
    ) { }

  ngOnInit(): void {
    this.velichesLength  = this.outputService.currentOutputState.vehicles.length;
    this.configService.isFinishVehiclePopupActive = true;
  }  

  RemoveAndContinue(){
    this.handleRemoveVehicle.emit();
  }

  closeFinish(){
    this.handleFinishVehicle.emit();
  }

  get config(): Config {
    return this.configService.config;
  }
}

<div id='ContinueCurrentStepOverlay'>
    <div class='continueStep-wrapper'>
        <button *ngIf="stepName === 'Coverage'" id='closeIcon' (click)="continueStep()">
            <em class='fa fa-times'></em></button>
        <button *ngIf="stepName === 'Extras'" id='closeIcon' (click)="continueExtrasStep()">
            <em class='fa fa-times'></em></button>    
        <img src="../../../../assets/images/WarningSecondary.svg" alt="Warning">
            <h1>Finish current step.</h1>
            <p>Please answer all questions for this step before going to another step.</p>
        <div id="continueStepButton">
            <button *ngIf="stepName === 'Coverage'" class="next finishVehicles" [style.background]="config.styles.primaryColor" (click)="continueStep()">
                CONTINUE
            </button>
            <button *ngIf="stepName === 'Extras'" class="next finishVehicles" [style.background]="config.styles.primaryColor" (click)="continueExtrasStep()">
                CONTINUE
            </button>
        </div>
    </div>
</div>
<div *ngIf="isDisplayErrorPopup" class="modal-info">
  <div class="modal-body">
    <button id='closeIcon' (click)='closeModal()'>
      <em class='fa fa-times fa-close'></em>
    </button>
    <div class="modal-content">
      <img src="../../../assets/images/error-poup-icon.svg" alt="error-popup-icon">
      <h3 class="modal-heading">Sorry!</h3>
      <p class="popup-text">Something went wrong, and we weren’t able to save your details. If you can, please go to the next page and try again, or continue through making a payment.</p>
      <button [ngClass]='{"popup-close-itm": this.isVendorITM}' [style.backgroundColor]='config.styles.primaryColor' type="submit" class="popup-close" (click)="closeModal()">CLOSE</button>
    </div>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

import { OutputService } from '../services/output.service';

@Pipe({
  name: 'parseVars'
})
export class ParseVariablesPipe implements PipeTransform {

  constructor(private outputService: OutputService) {}

  transform(text: string, ...args: any[]): string {
    return text
      .replace(/UNIT_NUMBER/g, this.outputService.currentVehicleState.id)
      .replace(/ACCOUNT_NUMBER/g, this.outputService.accountNumber)
      .replace(/ERROR_CODE/g, this.outputService.createAccountErrorCode);
  }

}

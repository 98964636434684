import { Component, AfterViewInit } from '@angular/core';
import { ConfigService } from 'src/app/data/services/config.service';
import { Config, Page} from 'src/app/data/models/config';
import { LoadExternalToolsGuard } from "src/app/data/services/exter-tools/external-tools.guard";
import { CustomPreloadingStrategy } from 'src/app/data/services/custom.preloadStrategy';
import { LaunchDarklyService } from 'src/app/data/services/launch-darkly/launch-darkly.service';

@Component({
  selector: 'app-welcome-otherVendor',
  templateUrl: './welcome-otherVendor.component.html',
  styleUrls: ['./welcome-otherVendor.component.scss']
})
export class WelcomeOtherVendorComponent implements AfterViewInit{
  isOwnerOpEnabled: boolean;
  isMercerEnabled: boolean;
  isBennettEnabled: boolean;
  flagSubscription: any;
  
  constructor(
    public configService: ConfigService,
    private loadExternalToolsGuard: LoadExternalToolsGuard,
    private customPreloadingStrategy: CustomPreloadingStrategy 
  ) {
    this.isOwnerOpEnabled =  this.configService.config.vendorId === "owner-op";
    this.isMercerEnabled = this.configService.config.vendorId === "mercer";
    this.isBennettEnabled = this.configService.isBennettVendor();
 
  }

  next() {
    this.configService.next();
  }
  
  get config(): Config {
    return this.configService.config;
  }

  get currentScreen(): Page {
    return this.configService.currentPage;
  }

  ngAfterViewInit(): void {
    // Load external scripts if welcome page is skipped
    try {
      if (!this.configService.externalScripsLoaded) {
        this.loadExternalToolsGuard.canActivate();
      }
    } catch (error) {
      console.warn(error);
    }
    this.customPreloadingStrategy.setPreload(true);
  }
}

import { InMemoryDbService } from 'angular-in-memory-web-api';
import ownerOpData from '../../assets/config/ownerop.json';
import { rvTollpassData } from './mock-data/rv-tollpass';
import efsData from '../../assets/config/efs.json';
import itmData from '../../assets/config/itm.json';
import mercerData from '../../assets/config/mercer.json';
import bennettData from '../../assets/config/bennett.json';
export class MockDb implements InMemoryDbService {
  createDb() {
    const partners = [
      itmData,
      ownerOpData,
      rvTollpassData,
      efsData,
      mercerData,
      bennettData
    ];

    return { partners };
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ServicePlan } from '../models/config';

@Injectable(
    {
        providedIn: 'root'
    }
)
export class DataService {

  private dataSource = new BehaviorSubject(false);
  private SuccessState = new BehaviorSubject(false);
  private StepperState = new BehaviorSubject(0);
  private PaymentNotReceived = new BehaviorSubject(false);
  private ServicePlanState = new BehaviorSubject<any>(undefined);
  private BaseServicePlanState = new BehaviorSubject<any>(undefined);
  private shouldDisplayStepper = new BehaviorSubject<boolean>(true);
  private shouldHideBackButton = new BehaviorSubject<boolean>(true);
  private shouldHideVehicleStep = new BehaviorSubject<boolean>(true);
  private reloadStepperSubject = new BehaviorSubject<void>(null);
  private reloadContactSubject = new BehaviorSubject<void>(null);
  currentData = this.dataSource.asObservable();
  currentSuccessState = this.SuccessState.asObservable();
  stepperState = this.StepperState.asObservable();
  PaymentNotReceivedState = this.PaymentNotReceived.asObservable();
  currentServicePlanState = this.ServicePlanState.asObservable();
  currentBasePlanState = this.BaseServicePlanState.asObservable();
  displayStepper = this.shouldDisplayStepper.asObservable();
  displayBackButtonVehicle = this.shouldHideBackButton.asObservable();
  displayVehicleStep = this.shouldHideVehicleStep.asObservable();
  reloadStepper$ = this.reloadStepperSubject.asObservable();
  reloadContact$ = this.reloadContactSubject.asObservable();

  updateData(value: boolean) {
    this.dataSource.next(value);
  }

  updateSuccessState(value: boolean){
    this.SuccessState.next(value);
  }

  updateStepperState(value: number){
  this.StepperState.next(value);
  }

  updatePaymentNotReceivedState(value: boolean){
    this.PaymentNotReceived.next(value);
  }

  updatePlanState(value: ServicePlan){
    this.ServicePlanState.next(value);
  }

  updateBasePlanState(value: ServicePlan){
    this.BaseServicePlanState.next(value);
  }

  updateDisplayStepper(value: boolean) {
    this.shouldDisplayStepper.next(value);
  }

  updateNoBackButtVehicle(value: boolean) {
    this.shouldHideBackButton.next(value);
  }

  updateVehicleStepDisplay(value: boolean) {
    this.shouldHideVehicleStep.next(value);
  }

  triggerReloadStepper() {
    this.reloadStepperSubject.next();
  }

  triggerContactReload() {
    this.reloadContactSubject.next();
  }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParseVariablesPipe } from '../data/directives/parse-variables.pipe';
import { SanitizePipe } from '../data/directives/sanitize.pipe';
import { AutofocusDirective } from '../data/directives/autofocus.directive';

@NgModule({
  declarations: [ParseVariablesPipe, SanitizePipe, AutofocusDirective],
  imports: [CommonModule],
  exports: [ParseVariablesPipe, SanitizePipe, AutofocusDirective]
})
export class SharedModule { }

<div id='finishVehiclesOverlay'>
    <div class='finishVehicles-wrapper'>
        <button id='closeIcon' (click)="closeFinish()">
        <em class='fa fa-times'></em></button>
        <img src="../../../../assets/images/WarningSecondary.svg" alt="Warning">
        <h1>Finish adding vehicles</h1>
    <p>You started adding a vehicle or trailer. Do you want to keep it and finish adding details, or remove it?</p>
    <div id="finihVehiclesButton">
    <button
    class="next finishVehicles"
    [style.background]="config.styles.primaryColor"
    (click)="closeFinish()"
    >
    FINISH
    </button>
    <button
    *ngIf="velichesLength > 1"
    class="next"
    id="removeVehicles"
    [style.background]="config.styles.primaryColor"
    (click)="RemoveAndContinue()"
    >
    REMOVE
    </button>
    </div>
    </div>
    </div>
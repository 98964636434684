import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/data/services/config.service';

const pageNotFoundScreenName = 'page-not-found';
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(
    private configService: ConfigService,
    ) { }

  ngOnInit(): void {
    // Set Meta title for Page Not Found page
    const pageNotFoundScreen = this.configService.config.screens.find(
      (screenObj) => screenObj.name === pageNotFoundScreenName
    );
    this.configService.setMetaTitleForScreen(null, pageNotFoundScreen?.metaTitle);
  }

  navigateToHome() : void {
    const firstPage = this.configService.config.screens[0];
    this.configService.next(firstPage.name);
  }

}

<mat-stepper [ngClass]='{"stepper-bg": this.isITMEnabled}' [selectedIndex]="this.selectedIndex"
  class="example-stepper"  (selectionChange)="selectionChange($event)" (click)="triggerClick()"
  [orientation]="(stepperOrientation | async)!">
  <ng-template matStepperIcon="edit" let-index="index"> <span>{{index + 1}} </span> </ng-template>
  <ng-container *ngIf="!isPaymentSuccess; else defaultSteps">
    <ng-container *ngFor='let item of renderSteps'>
      <mat-step
      [editable]="isStepEditable"
        label={{item.page}} [completed]="isStepCompleted(item.title,item.PageGroup)">
          <ng-template matStepLabel>
              <p class="tabopen" >{{item.title}}</p>
            </ng-template>
      </mat-step>
    </ng-container>
  </ng-container>
  <ng-template #defaultSteps>
    <ng-container *ngFor='let item of renderSteps'>
      <mat-step label={{item.page}} state="done">
        <ng-template matStepLabel>
          <p class="tabopen" >{{item.title}}</p>
        </ng-template>
      </mat-step>
    </ng-container>
  </ng-template>
</mat-stepper>
<app-finish-vehicles (handleFinishVehicle)="handleFinishVehicle()" (handleRemoveVehicle)="handleRemoveVehicle()" *ngIf="!configService.isCurrentVehicleComplete"></app-finish-vehicles>
<app-continue-current-step stepName="Coverage" *ngIf="!configService.isCoverageStepCompleted && isOwnerOp" (handleContinueStep)="handleContinueStep()"></app-continue-current-step>
<app-continue-current-step stepName="Extras" *ngIf="!configService.isExtrasStepCompletd && isOwnerOp" (handleContinueExtrasStep)="handleContinueExtrasStep()"></app-continue-current-step>
export const PageGroups = {
  itm: {
    coverage: ["coverage/car-make", "coverage/service-plan"],
    vehicles: ["vehicle/info", "vehicle/plate-reg"],
  },
  ownerop: {
    coverage: ["coverage/trucker-service-plan","coverage/new-service-plan","coverage/service-plan", "coverage/c-list", "coverage/toll-authority"],
    vehicles: [
      "vehicle/type",
      "vehicle/plate-reg",
      "vehicle/make",
      "vehicle/tractor",
      "vehicle/tractor-trailer",
      "vehicle/tandem-trailer-length",
      "vehicle/tractor-trailer-length",
      "vehicle/axles",
      "vehicle/tires",
      "vehicle/weight",
      "trailer/details",
      "trailer/make",
      "trailer/tractor-trailer-type",
      "trailer/axles",
      "trailer/type",
      "trailer/unitnumber",
      "vehicle/add-more",
    ],
    extras: [
      "ORDP",
      "ORDP/pre-existing-tickets",
      "ORDP/multiple-tickets",
      "extras/attribution",
    ],
    payment: [
      "extras/shippingaddress",
      "payment/shipping-speed",
      "extras/shippingaddressspeed",
      "payment/summary"
    ]
  },
};
<div id='activate-wrapper' [style.backgroundColor]='configService.config.styles.backgroundColor'
  [style.min-height]="configService.config.footer ? 'calc(100vh - 60px)' : '100vh'">


  <div *ngIf="isEfsEnabled; else otherVendor"  id='header-wrapper' [innerHTML]='configService.config.header | sanitizeHtml' (click)="home($event)"></div>
  <ng-template #otherVendor>
    <div id='header-wrapper'>
      <header id="custom-header">
          <div class="OO-itm-header">
            <div [innerHTML]='configService.config.header | sanitizeHtml' class="default-logo">
            </div>
            <div *ngIf="configService.associationLogoImg" class="association-logo-sec">
              <img [src]="configService.associationLogoImg" class="association-logo" [alt]="configService.associationLogoName"/>
              </div>
          </div>
          <ng-container *ngIf="(isOwnerOpEnabled || (isITMEnabled || this.configService.currentPage.name!='welcome')) && displayStepperComponent">
            <div id="stepperMainContainer" [ngClass]='{"stepper-container-itm": this.isITMEnabled}'>
            <div class="steps-header" [ngClass]='{"stepper-width-itm": this.isITMEnabled}'>
              <span class="steps-label">Steps</span>
              <a (click)="closeMenu()" class="closeMenu-icon">
                <svg xmlns="http://www.w3.org/2000/svg" class="close-icon-oo" [ngClass]='{"close-icon-itm": this.isITMEnabled}' height="24" viewBox="0 0 48 48" width="24"><path d="M38 12.83l-2.83-2.83-11.17 11.17-11.17-11.17-2.83 2.83 11.17 11.17-11.17 11.17 2.83 2.83 11.17-11.17 11.17 11.17 2.83-2.83-11.17-11.17z"/><path d="M0 0h48v48h-48z" fill="none"/></svg>
              </a>
            </div>
            <app-stepper id="stepperMenuCanadian" class="stepper-width" [ngClass]='{"stepper-width-itm": this.isITMEnabled}' *ngIf = "this.configService.currentPage.name !== 'welcome'"></app-stepper>
          </div>
          </ng-container>
          <div class="home-link" *ngIf="isOwnerOpEnabled">
           <a (click)="openMenu()" class="hamburger-menu-icon"  *ngIf = "this.configService.currentPage.name !== 'welcome' && displayStepperComponent"> <img id="HamburgerIcon" src="../../../assets/images/hamburger-menu-icon.svg" alt="menu-icon"></a>
          </div>
          <div *ngIf="isITMEnabled" class="home-link">
            <a *ngIf="this.configService.currentPage.name=='welcome'"  href='https://my.payviam.com/portal' target='_self' id='LoginHyperlink' >
              <button id='LoginButton' class='login-itm'>LOGIN</button>
            </a>
            <a (click)="openMenu()" class="hamburger-menu-icon" *ngIf = "this.configService.currentPage.name !== 'welcome'"> <img id="HamburgerIcon" src="../../../assets/images/hamburger-menu-icon.svg" alt="menu-icon"></a>
          </div>
          <div class="home-link" *ngIf="((isMercerEnabled || isBennettEnabled) && this.configService.currentPage.name !== 'welcome')">
            <a href="https://www.bestpass.com/" class="home-icon" (click)="home($event)">Home</a>
          </div>
      </header>
    </div>
  </ng-template>
  <div *ngIf="(!(configService.hiddenElements$ | async).navigation)  && (this.configService.currentPage.name !== 'welcome')" id='progress-wrapper'>
    <mat-progress-bar *ngIf='(configService.completion$ | async) as completion'
      mode='determinate' [value]='(completion.progress).replace("%","")' >
    </mat-progress-bar>
    <ng-container *ngIf="(this.configService.currentPage.name !== 'success' 
     && (this.isITMEnabled || 
     (this.configService.currentPage.name !== 'coverage/service-plan' && this.isEfsEnabled ) || 
     (this.configService.currentPage.name !== 'welcome' && this.configService.welcomeScreenFlag && this.isBennettEnabled) ||
    (this.configService.currentPage.name !== 'welcome' && this.configService.welcomeScreenFlag && this.isOwnerOpEnabled)|| 
    (this.configService.currentPage.name !== 'contact/info' && !this.configService.welcomeScreenFlag && this.isOwnerOpEnabled)||
    (this.configService.currentPage.name !== 'contact/info' && !this.configService.welcomeScreenFlag && this.isBennettEnabled)||
    (this.configService.currentPage.name !== 'coverage/service-plan' && !this.configService.welcomeScreenFlag && this.isMercerEnabled)||
    (this.configService.currentPage.name !== 'welcome' && this.configService.welcomeScreenFlag && this.isMercerEnabled))) && 
    this.displayBackButtonVehiclePage;else nobackbutton">
      <button id='back-button' class='back-button icon-button' (click)='back()'>
        <em class='far fa-angle-left'></em> Back
      </button>
    </ng-container>
    <ng-template #nobackbutton>
      <div [ngClass]='{"no-back-button": this.isEfsEnabled}' *ngIf="!this.isOwnerOpEnabled && !this.isMercerEnabled"></div>
    </ng-template>
  </div>

  <ng-container *ngIf="this.configService.currentPage.name == 'coverage/service-plan' || this.configService.currentPage.name == 'coverage/new-service-plan' && this.isOwnerOpEnabled">
    <app-promo-banner *ngIf="configService.promoBannerByLDWelcome && configService.isAttribution"></app-promo-banner>
  </ng-container>
  
  <router-outlet></router-outlet>

  <footer *ngIf="(!(configService.hiddenElements$ | async).footer) &&
    (this.configService.currentPage.name !== 'welcome' && this.configService.currentPage.name !== 'coverage/car-make')"
    class='footer'
    [ngClass]="{ 'flex-end': (configService.hiddenElements$ | async).cost || !(outputService.cost$ | async) }"
  >
    <ng-container *ngIf="!(configService.hiddenElements$ | async).cost && configService?.isFirstVehicleAdded">
      <div *ngIf="outputService.cost$ | async as cost" class="order-total">
        <p id="runningTotal">Order Total: ${{ cost | number: "1.2-2" }}</p>
        <p *ngIf="isOwnerOpEnabled" id="runningTotalText">Increases by $100 for each extra vehicle</p>
      </div>
    </ng-container>
    <div *ngIf="((outputService.cost$ | async) || configService.isSevicePlanPage()) && configService.config.paymentVendorLogos?.length" id="payment-logos-wrapper">
      <ng-container *ngFor="let logo of configService.config.paymentVendorLogos; let i = index; let last=last">
        <img [src]="logo.url" [alt]="logo.altText" />
        <div *ngIf="!last" class="seperator"></div>
      </ng-container>
    </div>
  </footer>
</div>
<div *ngIf='configService.config?.footer' id='custom-footer'><div [innerHTML]='configService.config.footer | sanitizeHtml'></div>
  <div><p class='footer-text'>&copy; {{ currentYear }}, Bestpass, Inc. &nbsp; | &nbsp; <a href='https://bestpass.com/privacy-policy' target='_blank' rel='noopener'>Privacy Policy</a></p></div>
</div>
<app-simple-loading-spinner *ngIf="configService.navigationInprogress"></app-simple-loading-spinner>
<app-success-popup [isDisplaySuccessPopup] = "configService.partialDataSuccessPopup"></app-success-popup>
<app-error-popup [isDisplayErrorPopup] = "configService.partialDataErrorPopup"></app-error-popup>
<app-simple-loading-spinner *ngIf="configService.LicensePlateValidationisInprocess"></app-simple-loading-spinner>
<app-simple-loading-spinner *ngIf="loadingPartialSignup"></app-simple-loading-spinner>
<app-simple-loading-spinner *ngIf="configService.isPartialSaveinProcess"></app-simple-loading-spinner>
<app-simple-loading-spinner *ngIf="outputService.addVehicleInProcess"></app-simple-loading-spinner>
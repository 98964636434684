<div class="banner">
    <ng-container *ngFor='let item of configService.config.promotionBanner'>
        <section class="banner-sec">
            <img class="img-left" [src]='item.bannerIcon' [alt]='item.bannerText' *ngIf='isDesktop' />
            <img class="img-left" [src]='item.bannerMobileIcon' [alt]='item.bannerText' *ngIf='!isDesktop' />
            <section class="banner-text">
                <p>{{item.bannerText}} </p>
                <span class="banner-sub-text">{{item.bannerSubText}}</span>
            </section>
            <img  class="img-right" [src]='item.bannerIconRight' [alt]='item.bannerText' *ngIf='isDesktop'/>
            <img class="img-right" [src]='item.bannerMobileIconLeft' [alt]='item.bannerText' *ngIf='!isDesktop' />
        </section>
    </ng-container>      
</div>
export const rvTollpassData = {
    "vendorId": "rv-tollpass",
    "vendorName": "RV TollPass",
    "header": "<header id='custom-header'><img width='100px' src='https://firebasestorage.googleapis.com/v0/b/meetup-83a22.appspot.com/o/image%20(3).png?alt=media&token=1646c919-89bc-4b96-ace4-d2558f0c90c1'></header><style></style>",
    "styles": {
      "primaryColor": "#004f9d",
      "backgroundColor": "#fafbfe"
    },
    "screens": [
      {
        "name": "contact/name",
        "progress": "8%",
        "next": "contact-email"
      },
      {
        "name": "contact-email",
        "progress": "15%",
        "next": "coverage/service-plan"
      },
      {
        "name": "coverage/service-plan",
        "progress": "23%",
        "servicePlans": [
          {
            "title": "Complete Pass",
            "territories": "CA*, CO*, FL, GA, KS, NC, OK, SC, TX, WA*",
            "mapUrl": "https://firebasestorage.googleapis.com/v0/b/meetup-83a22.appspot.com/o/Contingent%20States%20(2).png?alt=media&token=626a8588-6c9e-43ee-8ae6-0a7872b0efca",
            "activationFee": 80.00,
            "transponderFee": 13.00,
            "firstVehicleTollBalance": 350.00,
            "additionalVehicleTollBalance": 150.00,
            "hardwareFee": 50.00,
            "processingFee": 0.026,
            "accountMaintenanceFee": 5.00,
            "color": "#2E406B",
            "details": "*Includes plate read coverage\n1. There is a $80.00 activation fee per account, plus a one time $50.00 fee per transponder.\n2. Monthly fees are per transponder. Does not include the cost of any tolls incurred.\n3. Pre-paid toll balance for your first transponder is $350.00 each additional transponder on your account is $150.00. This is used to pay for tolls you incur.",
            "tollPlanId": 1
          },
        ],
        "next": "vehicle/transponder-id"
      },
      {
        "name": "vehicle/transponder-id",
        "progress": "30%",
        "next": "contact-phone"
      },
      {
        "name": "contact-phone",
        "progress": "38%",
        "next": "extras/shippingaddress"
      },
      {
        "name": "extras/shippingaddress",
        "progress": "45%",
        "header": "What is your address?",
        "label1": "Address",
        "next": "vehicle/type"
      },
      {
        "name": "vehicle/type",
        "progress": "53%",
        "next": {
          "RV-FLOW": "rv-make",
          "TRAILER-FLOW": "trailer/type"
        }
      },
      {
        "name": "vehicle/make",
        "alias": "rv-make",
        "progress": "60%",
        "next": "rv-state"
      },
      {
        "name": "vehicle/state",
        "alias": "rv-state",
        "progress": "68%",
        "next": "rv-plate"
      },
      {
        "name": "vehicle/plate",
        "alias": "rv-plate",
        "progress": "75%",
        "next": "vehicle/tow-other"
      },
      {
        "name": "vehicle/tow-other",
        "progress": "83%",
        "axles": [1, 2],
        "next": {
          "TOW-FLOW": "vehicle/add-more",
          "NOTOW-FLOW": "vehicle/add-more"
        }
      },
      {
        "name": "trailer/type",
        "progress": "60%",
        "next": {
          "STT-FLOW": "trailer-make-standard"
        }
      },
      {
        "name": "trailer/make",
        "alias": "trailer-make-standard",
        "progress": "68%",
        "next": "trailer-state-standard"
      },
      {
        "name": "trailer/state",
        "alias": "trailer-state-standard",
        "progress": "75%",
        "next": "trailer-plate-standard"
      },
      {
        "name": "vehicle/trailer-plate",
        "alias": "trailer-plate-standard",
        "progress": "83%",
        "next": "trailer-axles-standard-trailer"
      },
      {
        "name": "trailer/axles",
        "alias": "trailer-axles-standard-trailer",
        "progress": "90%",
        "axles": [2, 3],
        "next": "vehicle-axles-vehicle"
      },
      {
        "name": "vehicle/axles",
        "alias": "vehicle-axles-vehicle",
        "progress": "93%",
        "axles": [2, 3],
        "next": "vehicle/add-more"
      },
      {
        "name": "vehicle/add-more",
        "progress": "96%",
        "next": {
          "ADD-FLOW": "vehicle/type",
          "NO-FLOW": "payment/summary"
        }
      },
      {
        "name": "payment/summary",
        "progress": "99%",
        "next": "payment-success"
      },
      {
        "name": "template",
        "alias": "payment-success",
        "progress": "100%",
        "html": "<h1>Payment success html coming from config</h1>"
      }
    ]
  }
;

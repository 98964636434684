import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { LDClient, initialize } from 'ldclient-js';
import { environment } from 'src/environments/environment';
 
@Injectable({
  providedIn: 'root'
})
export class LaunchDarklyResolver implements Resolve<any> {
  private ldClient: LDClient;
  key: string;
  clientSideId: string;
 
  constructor() {
    this.setClientSideID();
    this.ldClient = initialize(this.clientSideId,
        { key: this.key, anonymous: true });
  }
 
  async resolve(): Promise<any> {
    return this.ldClient.waitForInitialization().then(() => {
      const flags = this.ldClient.allFlags();
      return flags;
    }).catch(error => {
      console.error('Error initializing LaunchDarkly:', error);
      return false;
    });
  }

  setClientSideID() {
    if (environment.envName == "Prod") {
      this.clientSideId = "62de9925d4e0561125da2bcf"
    }
    else {
      this.clientSideId = "62de9925d4e0561125da2bce"
    }
  }
}
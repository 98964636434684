import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

/* 
  Set meta title dynamically for vendors for landing page.
  In case of ITM, set it to first screen (Welcome)
  for with and without OEM query param cases.
*/
const setMetaTitleForLandingPage = () => {
  const patternForVendorForITMMetaTile = /itm/;
  const patternForVendorForefsMetaTile = /efs/;
  let title = 'Account Activation';
  const url = window.location.href;

  if (patternForVendorForITMMetaTile.test(url)) {
    title = (url.indexOf('coverage/service-plan') > -1) ? 'ITM Sign Up | Coverage' : 'ITM Sign Up | Welcome';
    if(url.indexOf('welcome') > -1) {
      const oem = url.split(/oem=/)[1];
      title = oem ? `${title} ${oem.charAt(0).toUpperCase() + oem.slice(1)}` : title;
    }
  }else if(patternForVendorForefsMetaTile.test(url)) {
    if(url.indexOf("contact/info") > -1) {
      title = 'Bestpass | Contact Info';
    } 
  }else {
    if(url.indexOf('coverage/service-plan') > -1) {
      title = 'Bestpass | Coverage';
    } 
  }
  document.title = title;
}
setMetaTitleForLandingPage();

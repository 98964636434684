export const environment = {
  production: true,
  useMockDB: false,
  onboardingAPI: "https://prod-bp-onboarding.azurewebsites.net/api",
  bestpassUrl: 'https://prod-bp-apis.azure-api.net/internal/onboarding/v1',
  spreadsheetTemplateUrl: "../../../assets/Vehicle Onboarding Template with Vehicle Details.xlsm",
  spreadsheetTemplateUrlOption1: "../../../assets/Vehicle Onboarding Template with VRC.xlsm",
  paymentUrl: 'https://api.paytrace.com',
  googleMapsUrl: 'https://maps.googleapis.com/maps/api/js?',
  gRecaptchaSiteKey: '6LeUhXsaAAAAAObVsFrVX846ODxEol0-lx9_tVgR',
  vendorConfigUrl: "https://prod-bp-onboarding-config.azurewebsites.net/api/partnerConfig",
  sentryDSN: "https://fb2a4896da8c4c4289e516702ca074ac@o278941.ingest.sentry.io/5566279",
  sentryTraceSampleRate: 0.01,
  envName: "Prod",
  onboardingURL:"https://onboarding.bestpass.com/#/activate",
  useSentry: true,
  useInspectlet: true,
  useGTM: true, // To use Google Tag Manager there is one more config key in json as googleTagManager for dev and prod
  useLogRocket: true
}
<form [formGroup]="form">
  <p class='payment-received-header'>
    <img src="../../../assets/images/tick.svg" alt="tick">
    Payment Received 
  </p>
  <div class='account-and-vehicle-details'>
    <ng-container *ngIf='isITMEnabled'>
      <div class='row'>
        <div>
          <label class='label'>Account#</label>
          <p class='value'>{{ accountNumber }}</p>
        </div>
        <div>
          <label class='label'>Vehicle</label>
          <p class='value'>{{ vehicleInfoDetails }}</p>
        </div>
      </div>
      <div class='row'>
        <div>
          <label class='label'>Coverage</label>
          <p class='value'>{{ coverageTitle | titlecase }}</p>
        </div>
        <div>
          <label class='label'>Renews On</label>
          <p class='value'>{{ renewsOn }}</p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf='!isITMEnabled'>
      <div class='row'>
        <div>
          <label class='label'>Account#</label>
          <p class='value'>{{ accountNumber }}</p>
        </div>
        <div>
          <label class='label'>Coverage</label>
          <p class='value'>{{ coverageTitle }}</p>
        </div>
      </div>
    </ng-container>
  </div>

  
  <h1 class='payment-label-text'>
  <ng-container *ngIf="isOwnerOpEnabled && configService.lastSelectedFlowPage === 'noVehicleFlow' ; else others">
    What's Next
  </ng-container>
  <ng-template #others>
    {{configService.config.paymentHeader}}
  </ng-template>
  </h1>
  <ng-container *ngIf="isOwnerOpEnabled && configService.lastSelectedFlowPage === 'noVehicleFlow' ; else otherSection">
    <p style="color: red;">
      {{configService.config.noVehicleFlowAccountGuidelinesHeader.text}}
    </p>
    <ol class='payment-header'>
    <ng-container *ngFor='let item of configService.config.noVehicleFlowAccountGuidelines' >
      <li class='payment-header-text' [innerHTML]="item.text">
      </li>
    </ng-container>
    </ol>
    <a class="help-link" href={{configService.config.noVehicleHelpLink.url}} target='_blank' rel='noopener'>{{configService.config.noVehicleHelpLink.urlName}}</a>
  </ng-container>
  <ng-template #otherSection>
    <ol class='payment-header'>
      <ng-container *ngFor='let item of configService.config.newAccountGuidelines' >
        <li class='payment-header-text'>
          {{item.text}}
        </li>
      </ng-container>
      <ng-container *ngIf='isORDPActive && configService.config.support as support' >
        <li class='payment-header-text' *ngIf="state?.addOnIds[0] === 18">
          {{support.text}}
          <span [innerHTML]='support.html | sanitizeHtml'></span>
        </li>
      </ng-container>
      <ng-container *ngIf = 'isBennettEnabled && configService.config.bennettdrivewyze as bennetttext'>
        <li class='payment-header-text'>
          {{bennetttext.text}}
          <span [innerHTML]='bennetttext.htmlcontent | sanitizeHtml' ></span>
          {{bennetttext.drivewyzetext}}
        </li>
      </ng-container>
    </ol>
  </ng-template>
  <ng-container *ngIf="isOwnerOpEnabled && configService.lastSelectedFlowPage === 'noVehicleFlow' ; else otherSectionComdata">
  </ng-container>
  <ng-template #otherSectionComdata>
  <section *ngIf="!isBennettEnabled && isAttributionID" class='payment-header'> 
    <ng-container *ngFor='let item of configService.config.comDataLink' >
      <div id='comdata-link'>
        <p class="comdata-text">{{item.text}}</p>
        <a class="comdata-link" href={{item.url}} target='_blank' rel='noopener'>{{item.urlName}}<svg xmlns="http://www.w3.org/2000/svg" id="Icon" class="comdata-link-icon" width="20" height="20" viewBox="0 0 24 24">
            <path id="Path_8160" data-name="Path 8160" d="M0,0H24V24H0Z" fill="none"/>
            <path id="Path_8161" data-name="Path 8161" d="M19,19H5V5h7V3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V12H19ZM14,3V5h3.59L7.76,14.83l1.41,1.41L19,6.41V10h2V3Z" fill="rgba(96,110,178,1)"/>
          </svg>
        </a>
      </div>
    </ng-container>
  </section>
  </ng-template>
  <ng-container *ngIf="isOwnerOpEnabled && configService.lastSelectedFlowPage === 'noVehicleFlow' ; else otherSectionReferralCode">
  </ng-container>
  <ng-template #otherSectionReferralCode>
    <ng-container *ngIf='(isMercerEnabled || isAttributionID) && !isBennettEnabled'>
      <p class='payment-header referral-code'>Use our referral code <span>Bestpass10</span> to get $10 off any permit, 
        or share it with a friend so they can use it.
      </p>
    </ng-container>
  </ng-template>
  <a
    *ngIf='isITMEnabled'
    href='{{ oemsFaqUrl }}'
    target='_blank'
    rel='noopener'
    class='instructions-by-oem-text'
    >{{ configService.config.instructionsByOemText }}
    <img
      class='new-window-icon'
      src='../../../assets/images/link.svg'
      alt='link-icon'
    />
  </a>

  <ng-container *ngIf='isOwnerOpEnabled || isMercerEnabled || isBennettEnabled'>
    <div>
      <h1 class="component-header">
        <ng-container *ngIf="currentScreen.header; else defaultHeader">{{
          currentScreen.header | parseVars
        }}</ng-container>
        <ng-template #defaultHeader> Create your Portal Account </ng-template>
        <p>On the next page, enter your email address and click ‘Send verification code’. Then enter the code from your email, and create your password.</p>
      </h1>

      <div class="display-center mb-32">
        <!-- false is passed as click event argument similar to vanilla js event handler -->
        <!-- We do this to fix page refresh bug on microsoft edge -->
        <button
          type="button"
          class="next"
          data-cy="success-btn"
          (click)="redirectToNewPortal()"
        >
          CREATE
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf='!isEFSEnabled && isITMEnabled'>
    <h1 class="component-header">
      <ng-container *ngIf="currentScreen.header; else defaultHeader">{{
        currentScreen.header | parseVars
      }}</ng-container>
      <ng-template #defaultHeader> Create your Portal Account </ng-template>
      <p>You can login on the next page.</p>
    </h1>

    <label>
      Email Address
    </label>
    <p id='email-address'>{{ emailId }}</p>

    <label for="password" class="required-asterisk">Password</label>
    <div class="show-password-block">
      <input
        formControlName="password"
        [type]="isPasswordHidden ? 'password' : 'text'"
        id="password"
        data-cy="password-input"
      />
      <button class="show-password" (click)="togglePassword()"><em [class]="isPasswordHidden ? 'fas fa-eye-slash' : 'fas fa-eye'"></em></button>
    </div>
    <div *ngIf="form.controls.password.touched && form.controls.password.errors">
      <p *ngIf="form.controls.password.touched && form.controls.password.errors.required" class="error-message" >
        Password is required
      </p>
      <p
        *ngIf="
          form.controls.password.touched &&
          form.controls.password.errors.minlength
        "
        class="error-message"
      >
        Password must be 8 characters
      </p>
    </div>
    <label for="confirm-password" class="required-asterisk">Confirm Password</label>
    <div class="show-password-block">
      <input
        formControlName="confirmPassword"
        [type]="isConfirmPasswordHidden ? 'password' : 'text'"
        id="confirm-password"
        data-cy="confirmPassword-input"
      />
      <button class="show-password" (click)="toggleConfirmPassword()"><em [class]="isConfirmPasswordHidden ? 'fas fa-eye-slash' : 'fas fa-eye'"></em></button>
    </div>
    <p *ngIf="form.controls.confirmPassword.touched && form.controls.confirmPassword.errors?.noMatch"
      class="error-message"
    >
      Passwords must match
    </p>

    <div class="display-center mb-32">
      <!-- false is passed as click event argument similar to vanilla js event handler -->
      <!-- We do this to fix page refresh bug on microsoft edge -->
      <button
        type="submit"
        class="next"
        data-cy="success-btn"
        (click)="callSignupAssociatePortalAPI()"
        [disabled]="form.invalid"
      >
        CREATE
      </button>
    </div>
  </ng-container>
</form>
<div *ngIf='isAPISuccessResponse' class='success-modal'>
  <div style='margin: 20px;'>
    <div class='modal'>
      <button (click)='redirectToUrl()' class='close'>
        <em class='fa fa-times'></em></button
      ><em class='fas fa-check-circle fa-4x'></em>
      <h3>Thank you!</h3>
      <p>We’ve activated your login.</p>
      <button
        type='button'
        class='next'
        data-cy='success-btn'
        (click)='redirectToUrl()'
      >
        CONTINUE TO LOGIN
      </button>
      <p *ngIf='currentScreen.html' class='notify-redirect'>{{ currentScreen.html | parseVars }}</p>
    </div>
  </div>
</div>
<div *ngIf='isApiTriggered && !!errorMsgFromAPI' class='error-modal'>
  <div style='margin: 20px;'>
    <div class='modal'>
      <button (click)='close()' class='close'>
        <em class='fa fa-times'></em></button
      ><em class='far fa-exclamation-circle fa-3x'></em>
      <h3>Sorry!</h3>
      <p>{{ errorMsgFromAPI }}</p>
    </div>
  </div>
</div>
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Config, Page } from 'src/app/data/models/config';
import { ConfigService } from 'src/app/data/services/config.service';
import { OutputService } from 'src/app/data/services/output.service';

@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss']
})
export class ErrorPopupComponent implements OnInit{
  isVendorOwnerOp: boolean = false;
  isVendorITM: boolean = false;
  isVendorEFS: boolean = false;
  isVendorMercer: boolean = false;
  isErrorModal: boolean = false;

  @Input() isDisplayErrorPopup: boolean;

  constructor(public configService: ConfigService, private outputService: OutputService) { }

  ngOnInit() {
    this.isVendorOwnerOp = this.configService.isOwnerOpVendor();
    this.isVendorITM = this.configService.isITMVendor();
    this.isVendorEFS = this.configService.isEFSVendor();
    this.isVendorMercer = this.configService.config.vendorId === "mercer";
  }

  closeModal() {
    this.configService.partialDataErrorPopup = false;
  }
  
  @HostListener('document:keydown', ['$event'])
  onKeyDownHandler(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      this.closeModal();
    }
  }

  get config(): Config {
    return this.configService.config;
  }

  get currentScreen(): Page {
    return this.configService.currentPage;
  }

}

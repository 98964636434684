import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<any> {
  
  canDeactivate(): boolean {

    if (confirm("Leaving will cause you to lose your progress. " +
      "Are you sure you want to leave anyways?")) {

      return true

    } else {
      return false
    }
  }
}

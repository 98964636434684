<section class="row bestpass-toll mobile-view position-rel">
    <section class="column ">
     <div class="truck-img"></div>
    </section>
</section>
<section class="row bestpass-toll desktop-view">
    <section class="column title">
        <p class="title-text">Bestpass Takes Toll Off Your Plate</p>
    </section> 
    <section class="column">
      <div class="truck-img"></div>
      <div class="stripe"></div>
    </section>     
</section>
<section class="row column-reverse-view">
    <section class="column left-section-xs-height">  
        <div class="left-section">
            
            <div class="left-content">
                <p class="mobile-view">When you sign up for Bestpass you will get one monthly statement online showing every toll in one place. No more paper bills getting sent to your P.O. box or mail weeks after you went through a toll. We know tolls can be a pain, but we make it simple and give you discounts on tolls across the country to save you money.</p>
                <p class="desktop-view">When you sign up for Bestpass you will get one monthly statement online showing every toll in one place. No more paper bills getting sent to your P.O. box or mail weeks after you went through a toll.</p>
                <p class="desktop-view">We know tolls can be a pain, but we make it simple and give you discounts on tolls across the country to save you money.</p>
                <div  class="pickup-coverage-sec padding-xs">
                    <button (click)="next();">{{isBennettEnabled ? 'SIGNUP TODAY' : 'PICK YOUR COVERAGE'}} <span><img class="arrow-icon" src="../../../assets/images/right-arrow-icon.svg" alt="right-arrow-icon"></span></button>
                </div>
                <div class="laptop-stripe stripe desktop-view"></div>
            </div>
        </div>
        <img src="../../../../../assets/images/laptop-welcome.webp" class="laptop-img" alt="laptopimg">
    </section>
    <section class="column ">
        <div class="right-section">
            <section class="column title-text-xs mobile-view">
                <div class="title">
                  <p class="title-text">How Bestpass Toll Management Works</p>
                </div>
                <section class="save-toll-management">
                    <p>Save up to $280 a year with Bestpass toll management</p>
                </section>
            </section>
           <p class="toll-title desktop-view">How Bestpass Toll Management Works</p>
            <div class="toll-content">
                <div class="toll-odd-content">
                    <div class="toll-number"><p class="toll-number-value">1</p></div>                    
                    <div class="list-content">
                        <p class="toll-list-content toll-list-even" *ngIf="isOwnerOpEnabled">Sign up and pay a toll deposit today. Additionally, you'll pay a monthly fee of only $13-$15, depending on the coverage you need.</p>
                    </div>
                    <div class="list-content"><p class="toll-list-content toll-last-content toll-mercer" *ngIf="(isMercerEnabled || isBennettEnabled)">Sign up for our Complete Pass coverage plan and pay a toll deposit today. Additionally, you'll pay a monthly fee of only $3.50.</p></div>
                </div>
                <div class="toll-odd-content">
                    <div class="toll-number"><p class="toll-number-value">2</p></div>
                    <div class="list-content">
                        <p class="toll-list-content toll-list-even toll-list-ship">We ship your transponder to wherever you select. Install the toll transponder and remember to close out any other toll account(s)!</p>
                    </div>
                </div>
                <div class="toll-odd-content toll-drive-content">
                    <div class="toll-number"><p class="toll-number-value">3</p></div>
                    <div class="list-content">
                        <p class="toll-list-content">Drive your routes and Bestpass pays for any toll charge from your prepaid account. Whenever your account dips below 50% of your deposit amount, we’ll replenish it automatically.</p>
                    </div>
                    </div>
                <div class="toll-odd-content toll-monthly-content">
                    <div class="toll-number"><p class="toll-number-value">4</p></div>
                    <div class="list-content">
                        <p class="toll-list-content toll-list-even">You will get a monthly statement sent to your email with all the tolls you paid that month.</p>
                    </div>
                </div>
                <div class="toll-odd-content toll-log-content">
                    <div class="toll-number"><p class="toll-number-value">5</p></div>
                    <div class="list-content">
                        <p class="toll-list-content toll-last-content">Log in anytime to our online portal to view your toll transactions and account balance.</p>
                    </div>
                </div>
            </div>
            <section class="benefits-section">
                <p class="toll-title benefits-title">Benefits</p> 
                <section class="benefits-content">
                    <div class="benefits-sec"></div>
                    <div class="benefits-row">
                        <div class="benefits-box">
                        <p>Discounts on tolls across the country</p>
                        </div>
                        <div class="benefits-box">
                        <p>No more paper bills from violations</p>
                        </div>
                        <div class="benefits-box benefits-box-last-xs">
                            <p class="benefits-box-last-child">One toll bill and access 24/7 online to your toll spend</p>
                        </div>
                    </div>
                </section>
           </section>
        </div>
    </section>
</section>
<div id='bg-wrapper' [style.backgroundImage]='bgURL'>
    <div class='container'>
        <header class='hero-section'>
            <div class='hero-text'>
                <h1>Activate your ITM<sup><em class='far fa-registered registered-icon'></em></sup>
                    benefits today</h1>
                    <p>
                       Your ITM replaces your toll tags and takes you coast to coast with one account
                    </p>
                <button class='signup-itm btn' (click)='next()'>sign up now</button>
            </div>

            <div class='video-wrapper'>
                <iframe title='ITM introductory video' [src]='videoLink' width='100%' height='100%'
                    allow='autoplay; fullscreen; picture-in-picture' allowfullscreen>
                </iframe>
            </div>
        </header>
    </div>

</div>
<div id='sub-info'>
    <div class='container'>
        <p>
            It’s a new era in tolling: no transponder in your windshield and no geographic constraints. Are you taking a
            road trip or relocating? Payviam works with all major toll roads in the U.S., and we are constantly adding
            more. One account and one seamless experience.
        </p>

        <div id='work-benefits-container'>

            <section>
                <label class='sub-info-label'>How It Works:</label>
                <ul>
                    <li><span>Enroll here and fund your account</span></li>
                    <li><span>As you run tolls, we deduct those from your pre-paid account</span></li>
                    <li><span>When your account runs low, we will recharge it</span></li>
                </ul>
            </section>

            <section>
                <label class='sub-info-label'>Benefits Include:</label>
                <ul>
                    <li><span>Compatibility with 40+ toll authorities, yet one account</span></li>
                    <li><span>Parking at 11 airports (so far) and two other major venues</span></li>
                </ul>
            </section>

        </div>
    </div>
</div>
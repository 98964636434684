import { Component } from '@angular/core';
import { Config } from 'src/app/data/models/config';
import { ConfigService } from 'src/app/data/services/config.service';

@Component({
  selector: 'app-promo-banner',
  templateUrl: './promo-banner.component.html',
  styleUrls: ['./promo-banner.component.scss']
})
export class PromoBannerComponent {
  isDesktop:boolean;

  constructor(public configService: ConfigService) {
    this.isDesktop = window.innerWidth > 1259;
  }
  get config(): Config {
    return this.configService.config;
  }
  
}

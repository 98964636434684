import { Component, OnInit } from '@angular/core';
import { Config } from 'src/app/data/models/config';
import { ConfigService } from 'src/app/data/services/config.service';
@Component({
  selector: 'app-simple-loading-spinner',
  templateUrl: './simple-loading-spinner.component.html',
  styleUrls: ['./simple-loading-spinner.component.scss']
})
export class SimpleLoadingSpinnerComponent implements OnInit{
  isITMEnabled: boolean;
  isOwnerOpEnabled: boolean;
  isEFSEnabled: boolean;
  isMercerEnabled: boolean;
  isBennettEnabled: boolean;
  constructor(
    private configService: ConfigService
  ) { }

  ngOnInit() {
    this.isITMEnabled = this.configService.isITMVendor();
    this.isEFSEnabled = this.configService.isEFSVendor();
    this.isBennettEnabled = this.configService.isBennettVendor();
    this.isOwnerOpEnabled = this.configService.config.vendorId === "owner-op";
    this.isMercerEnabled = this.configService.config.vendorId === "mercer";   
  }

  get config(): Config {
    return this.configService.config;
  }

}
<div *ngIf="isDisplaySuccessPopup" class="modal-info">
    <div class="modal-body">
      <button id='closeIcon' (click)='closeModal()'>
        <em class='fa fa-times fa-close'></em>
      </button>
      <div class="modal-content">
        <img *ngIf="isVendorITM" src="../../../../assets/images/thanks-popup-icon-itm.svg" alt="success-popup-icon-itm">
        <img *ngIf="isVendorOwnerOp" src="../../../../assets/images/thanks-popup-icon-oo.svg" alt="success-popup-icon">
        <img *ngIf="!isVendorOwnerOp && !isVendorITM" src="../../../../assets/images/thanks-popup-icon.svg" alt="success-popup-icon">
        <ng-container  *ngIf='configService.config.successPopup as successPopup'>          
          <h3 class="modal-heading">{{successPopup.heading}}</h3>
          <p class="popup-title" [ngClass]='{"popup-title-efs": this.isVendorEFS}'>{{successPopup.popupTitle}}</p>
          <p class="popup-text">{{successPopup.popupSubtitle}}</p>
          <a rel="noopener" href="{{itmRedirectLink}}{{vendorName}}{{configService.customerKey}}" target="_blank" class="customer-key-link customer-key-link-itm" [style.color]='config.styles.successPopupLink' *ngIf="isVendorITM && enviornmentName =='Prod'; else anotherVendor ; else otherEnv">{{itmRedirectLink}}{{vendorName}}{{configService.customerKey}}</a>
          <ng-template #anotherVendor>
            <a rel="noopener" href="{{OnboardingLink}}{{vendorName}}{{configService.customerKey}}" target="_blank" class="customer-key-link" [style.color]='config.styles.successPopupLink' [ngClass]='{"customer-key-link-efs": this.isVendorEFS}'>{{OnboardingLink}}{{vendorName}}{{configService.customerKey}}</a>
          </ng-template>
          <ng-template #otherEnv>
            <a rel="noopener" href="{{OnboardingLink}}{{vendorName}}{{configService.customerKey}}" target="_blank" class="customer-key-link" [style.color]='config.styles.successPopupLink' [ngClass]='{"customer-key-link-efs": this.isVendorEFS}' [ngClass]='{"customer-key-link-itm": this.isVendorITM}'>{{OnboardingLink}}{{vendorName}}{{configService.customerKey}}</a>
          </ng-template>
          <p class="popup-text">{{successPopup.popupContent}}</p>         
          <button [ngClass]='{"popup-close-itm": this.isVendorITM}' [style.backgroundColor]='config.styles.primaryColor' type="submit" class="popup-close" (click)="closeModal()">CLOSE</button>
        </ng-container>
      </div>
    </div>
</div>  
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {initialize as LDClientInitialize } from 'ldclient-js';
import { FlagNames } from "./flagnames";
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

export class LaunchDarklyService {
  ldClient: any;
  flags: any;
  key: string;
  clientSideId: string;
  flagChange: Subject<any> = new Subject<any>();

  constructor() {    
    this.setClientSideID();
    
    this.flags = {  };
    this.key = uuidv4();
    this.ldClient = LDClientInitialize(this.clientSideId,
    { key: this.key, anonymous: true });
    this.ldClient.on('error', ()=>{ console.warn("LaunchDrakly Network Disconnected")});
    this.ldClient.on('change', (flags: any) => {
      for (let flag in flags) {
        // take the current value of the flag
        this.flags[flag] = flags[flag].current;
    }
      this.flagChange.next(this.flags);
    });

    this.ldClient.on('ready', () => {
        this.setFlags();      
        this.ldClient.close();
    })
  }

  setClientSideID() {
    if (environment.envName == "Prod") {
      this.clientSideId = "62de9925d4e0561125da2bcf"
    }
    else {
      this.clientSideId = "62de9925d4e0561125da2bce"
    }
  }

  getFlag(flagName: FlagNames): string {
    let flag = this.flags[flagName];
    if (flag == undefined) {
      this.flags[flagName] = false;
      flag = false;          
    }
    return flag;
  }

  setFlags() {
    this.flags = this.ldClient.allFlags();
    this.flagChange.next(this.flags);
  }
}
import { Component, OnInit } from '@angular/core';
import { OutputService } from "./data/services/output.service";
import { ConfigService } from './data/services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor(
    public outputService: OutputService,
    public configService: ConfigService
  ) {}

  ngOnInit() {
    const outputState = this.outputService.currentOutputState;
    if (!outputState.company) {
      outputState.company = {}
    }


    //query parameter magic for the unique sales url
    //comes in search for standard but for redirects like EFS it comes with the hash
    const query = window.location.search != '' ? window.location.search : window.location.hash.substring(window.location.hash.indexOf('?') + 1);

    const params = new URLSearchParams(query);

    outputState.company.salesPersonId = params.get('ref');
    
    this.outputService.updateState(outputState);
  }

  HideStepper($event) {
    if($event.target.id !== "HamburgerIcon" && window.innerWidth < 800 && !this.configService.isFinishVehiclePopupActive ){
    try{
      const StepperRef:HTMLElement = document.getElementById("stepperMainContainer");
      StepperRef.style.display = "none";
    }
    catch(error){
      return;
    }
    }
  }
}

import { Component } from '@angular/core';
import { VendorIdConfig } from 'src/app/data/models/config';
import { ConfigService } from 'src/app/data/services/config.service';
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
})
export class WelcomeComponent {
  vendorIdConfig = VendorIdConfig;
  vendorId: string;
  
  constructor (configService: ConfigService){
    this.vendorId = configService.config.vendorId;
   }
}

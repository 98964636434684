import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatStepperModule } from '@angular/material/stepper';
import {CdkStepperModule} from '@angular/cdk/stepper';
import { MatIconModule } from '@angular/material/icon';
@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSelectModule,
    MatStepperModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatProgressBarModule,
    ClipboardModule,
    CdkStepperModule,
    MatIconModule
  ],
  exports: [
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSelectModule,
    MatStepperModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatProgressBarModule,
    ClipboardModule,
    CdkStepperModule,
    MatIconModule
  ],
})
export class MaterialModule {}

import { DefaultUrlSerializer,UrlTree } from '@angular/router';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
    parse(url: string): UrlTree {
        if (url.includes('?')) {
            const urlWithoutQueryString = url.split('?')[0];
            const queryString = url.split('?')[1];
            return super.parse(`${urlWithoutQueryString.toLowerCase()}?${queryString}`);
        }
        return super.parse(url.toLowerCase());
    }
}
import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { environment } from "src/environments/environment";
import { ConfigService } from "../config.service";

interface LogRocketType {
  init(appId: string): void;
}

declare global {
  interface Window {
    LogRocket?: LogRocketType;
  }
}

@Injectable({
  providedIn: "root",
})
export class LoadExternalToolsGuard implements CanActivate {
  constructor(public configService: ConfigService) {}

  canActivate(): boolean {
    if (environment.useLogRocket) {
      this.loadExternalTool(true, "logRocketProjectId");
    }
    if (environment.useGTM) {
      this.loadExternalTool(true, "googleTagManager");
    }
    if (environment.useInspectlet) {
      this.loadExternalTool(true, "inspectLetWid");
    }
    this.configService.externalScripsLoaded = true;
    return true; // allow navigation to continue
  }

  public loadInspectlet(wid: number) {
    let script = document.createElement("script");
    script.innerHTML = `(function () {try{window.__insp = window.__insp || []; __insp.push(["wid", ${wid}]); var ldinsp = function(){ if(typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement("script"); insp.type = "text/javascript"; insp.async = true; insp.id = "inspsync"; insp.src = ("https:" == document.location.protocol ? "https" : "http") + "://cdn.inspectlet.com/inspectlet.js?wid=${wid}&r=" + Math.floor(new Date().getTime()/3600000); var x = document.getElementsByTagName("script")[0]; x.parentNode.insertBefore(insp, x); };setTimeout(ldinsp, 0);}catch(error){console.warn(error);}})();`;
    const head = document.getElementsByTagName("head")[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(script);
    }
  }

  public loadGtmContainer(gtmID: string) {
    (window as any).dataLayer = (window as any).dataLayer || [];

    const scriptGtm = document.createElement("script");
    scriptGtm.type = "text/javascript";
    scriptGtm.id = "gtm-container";
    scriptGtm.innerHTML = `(function(w, d, s, l, i) {try {w[l] = w[l] || [];w[l]?.push({'gtm.start': new Date().getTime(), event: 'gtm.js'});var f = d?.getElementsByTagName(s)[0],j = d?.createElement(s),dl = l !== 'dataLayer' ? '&l=' + l : '';j.async = true;try {j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;} catch (error) {console.warn('Error setting GTM script source:', error);}f.parentNode.insertBefore(j, f);} catch (error) {console.warn('Error executing GTM script:', error);}})(window, document, 'script', 'dataLayer', '${gtmID}');`;
    const head = document.getElementsByTagName("head")[0];

    if (head !== null && head !== undefined) {
      const elem = document.getElementById("gtm-container");
      if (elem) {
        elem.parentNode.removeChild(elem);
      }
      document.head.appendChild(scriptGtm);
    }
  }

  private loadLogRocketScript(scriptUrl: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement("script");
      scriptElement.src = scriptUrl;
      scriptElement.onload = resolve;
      scriptElement.onerror = reject;
      document.body.appendChild(scriptElement);
    });
  }

  public loadExternalTool(toUse: boolean, configPropName: string) {
    const environmentName = environment.envName
      .replace(/\./g, "")
      .toLowerCase();
    const toolsId =
      this.configService.config[configPropName]?.[environmentName];

    //Avoid render locking of welcome page by calling external scripts after welcome age is loaded.
    const timeDelay = 4000;
    if (toUse && toolsId) {
      switch (configPropName) {
        case "googleTagManager":
          setTimeout(() => {
            this.loadGtmContainer(toolsId);
          }, timeDelay);
          break;
        case "inspectLetWid":
          setTimeout(() => {
            this.loadInspectlet(toolsId);
          }, timeDelay);
          break;
        case "logRocketProjectId":
          setTimeout(() => {
            this.loadLogRocketScript(
              "https://cdn.lr-in-prod.com/LogRocket.min.js"
            ).then(() => {
              if (window?.LogRocket) {
                window.LogRocket.init(toolsId);
              }
            });
          }, timeDelay);
          break;
      }
    }
  }
}

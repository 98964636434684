import { Injectable } from "@angular/core";
@Injectable()
export class AppConfig {
  
    /** generated via method described https://gist.github.com/gordonbrander/2230317 */
    public adminIdWhitelist = {
    	"a" : 1,
    	"b" : 1,
	    "my6w9pdqd": 1,
	    "dmrxxuttk": 1,
	    "1p48hzqac": 1,
	    "43rxp3pen": 1,
	    "latgdutrg": 1,
	    "4wj1da47w": 1,
	    "ibie2hxw6": 1,
	    "n5o0zy8z4": 1,
	    "4e8pya3oq": 1,
	    "lok8e9apc": 1
	};

};
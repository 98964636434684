import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, from } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaytraceInitResolver implements Resolve<void> {
  resolve(): Observable<void> {
    return from(this.loadScript(environment?.envName === "Prod" ? "https://api.paytrace.com/assets/e2ee/paytrace-e2ee.js":"https://api.sandbox.paytrace.com/assets/e2ee/paytrace-e2ee.js"));
  }

  loadScript(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve();
      };
      script.onerror = (error) => {
        reject(error);
      };
      document.body.appendChild(script);
    });
  }
}

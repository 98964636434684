import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { Output, Vehicle, TrailerType, LicensePlateType, OrdpTicket } from '../models/output';
import { BillingAddress } from '../models/payment';
import { OnboardingApiService } from './onboarding-api.service';
import { ServicePlan } from '../models/config';
import {v4 as uuid} from 'uuid'
@Injectable({
  providedIn: 'root'
})
export class OutputService {
  isPartialDataIngestFlow: boolean = false;
  isSpreadsheetFlow: boolean = false;
  accountNumber: string;
  createAccountErrorCode: string;
  signUpVal: string;
  ticketVal: string;
  ticketNeeded: string;
  vehicleRig: string;
  YesFlowPreselected: boolean = false;
  YesFlowPreselectedOldState: boolean = false;
  isTrailerAdded:boolean = false;
  AddNewVehicleFlow: boolean = true;
  currentFlow: string = "BUS";
  OldFlow:string = "BUS";
  AddTrailerFlow: boolean = false;
  tractorTrailerVal: string;
  private lastVehicleStoredCopy: Vehicle;
  initialVehicleId: string = uuid();
  lastVehicleId: string = "";
  private _output: BehaviorSubject<Output> = new BehaviorSubject({ vehicles: [{key:this.initialVehicleId}] });
  initilalVehicleDetails:Vehicle = {key:this.initialVehicleId};
  output$: Observable<Output> = this._output.asObservable();
  private licensePlateTypeStr: LicensePlateType;
  private isUpdatePayvium = false;
  private billingAdress: BillingAddress;
  isTractorEdit: boolean = false;
  ordpTicket: OrdpTicket;
  ordpTicketCount: number = 0;
  isPartialSignupFlow: boolean = false;
  planToCalculate: ServicePlan;
  isFeesModal: boolean = false;
  addVehicleInProcess: boolean = false;
  vehicleCountNoTrailerLength: number;

  cost$: Observable<any> = this.output$.pipe(
    filter(output => !!output.servicePlan),
    map(output => {
      const plan = output.servicePlan

      let isFirstVehicleSelected: boolean = false;
      let isTrailerSelectedFirst: boolean = false;
     if (output.SelectedVehicleCount >= 1){
      // Condition.
     }
     else{
      return output.vehicles.reduce((acc, vehicle, i, vehicles) => {
        if (vehicle.type == TrailerType.TRACTORTRAILER ||
          vehicle.type == TrailerType.STANDARD ||
          vehicle.type == TrailerType.FIFTHWHEEL || vehicle.type == 'TRAILER') {
          if (!isFirstVehicleSelected) {
            isTrailerSelectedFirst = true;
          }
          // no variable fees for trailers
          if (this.ticketVal === "YES") {
            if (i + 1 === vehicles.length) {
              return acc + (this.currentOutputState.servicePlan?.ORDP?.flatFee * this.ordpTicketCount);
            } else {
              return acc
            }
          } else {
            return acc
          }
        }

        isFirstVehicleSelected = true;
        let vehicleCost: number = plan.hardwareFee

        if (i == 0 || isTrailerSelectedFirst) {
          vehicleCost += plan.firstVehicleTollBalance;
          isTrailerSelectedFirst = false;
        } else {
          vehicleCost += plan.additionalVehicleTollBalance
        }

        if (this.ticketVal === "YES") {
          if (i + 1 === vehicles.length) {
            return acc + vehicleCost + (this.currentOutputState.servicePlan?.ORDP?.flatFee * this.ordpTicketCount);
          }
          else {
            return acc + vehicleCost;
          }
        }
        else {
          return acc + vehicleCost;
        }

      }, 0 as number) + plan.activationFee + plan?.annualFee
    }
    })
  )

  SubjectNotifier: Subject<string> = new Subject<string>();

  constructor(private onboardingAPI: OnboardingApiService) {

  }

  nextPage(name: string) {
    this.SubjectNotifier.next(name);
  }

  get currentOutputState() {
    return { ...this._output.value }
  }

  get lastVehicleAddedState() :Vehicle{
    return this.lastVehicleStoredCopy;
  }

  CheckTrailer(Input: string): boolean {
    if (!Input) return false

    for (let X in TrailerType) {
      if (Input == TrailerType[X]) {
        return true
      }
    }
    return false
  }

  get currentVehicleState(): Vehicle {
    const vehicles = this.currentOutputState.vehicles;
   let currentVehicleType = vehicles[vehicles.length - 1].type;
   if (this.CheckTrailer(currentVehicleType)){
    let vehiclesArray = vehicles.filter(element => {
      return this.CheckTrailer(element.type);
    });
    return vehiclesArray[vehiclesArray.length - 1];
   }
   else {
    return vehicles[vehicles.length - 1];
   } 
  }

  get currentClassState(): object {
    const state = {
      isPartialDataIngestFlow: this.isPartialDataIngestFlow,
      isSpreadsheetFlow: this.isSpreadsheetFlow,
      accountNumber: this.accountNumber,
      createAccountErrorCode: this.createAccountErrorCode,
      signUpVal: this.signUpVal,
      ticketVal: this.ticketVal,
      initialVehicleId:this.initialVehicleId,
      ticketNeeded: this.ticketNeeded,
      lastVehicleStoredCopy: this.lastVehicleStoredCopy,
      licensePlateTypeStr: this.licensePlateType,
      isUpdatePayvium: this.isUpdatePayvium,
      billingAdress: this.billingAdress,
      ordpTicket: this.ordpTicket,
      ordpTicketCount: this.ordpTicketCount,
    };
    return state;
  }

  get currentDeviceVehicleState(): Vehicle {
    return  this.currentOutputState.DeviceVehicleInfo;
  }

  updateState(update: Output): void {

    const newState: Output = {
      ...this.currentOutputState,
      ...update
    }

    this._output.next(newState)
  }

  overwriteOutputeState(update: Output){
    this._output.next(update);
  }

  deleteProperty(property: string, ticketVal?: string, ordpTicketCount?: number, ordpticketproperty?: string){
    let currentState = this.currentOutputState;
    delete currentState[property];
    delete currentState[ordpticketproperty];
    this._output.next(currentState);
  }

  addVehicle(duplicate: boolean = false,addMoreFlow?: string,isTrailerOwned?:boolean,tractorKey?:string): string {
    const newVehicleKey = uuid();
    let newVehicle: Vehicle = {
      key:newVehicleKey,
      addMoreState:addMoreFlow,
      addMoreValue:addMoreFlow !== "TrailerCombo"?addMoreFlow:"NO",
      comboTractorkey: tractorKey }

    if(isTrailerOwned){
      newVehicle ={
        ...newVehicle,
        isTrailerOwned:this.tractorTrailerVal === "Yes"
      }
    }

    if (duplicate) {

      //deep copy vehicle
      newVehicle = JSON.parse(JSON.stringify(this.currentVehicleState))
      this.lastVehicleStoredCopy = newVehicle;

      // delete fields that should not be duplicate
      delete newVehicle.licenseplate.number;
      delete newVehicle.id;
      delete newVehicle.vin;


    } else {
      this.lastVehicleStoredCopy = null;
      newVehicle.licenseplate = {
        country: "USA",
        state: ''
      }
    }

    this.currentOutputState.vehicles.push(newVehicle);
    this.lastVehicleId = newVehicleKey;
    return newVehicleKey;
  }

  removeRecentVehicle(): void {
    this.currentOutputState.vehicles.pop();
  }

  updateVehicleState(vehicle: Vehicle,VehicleIndex?:number): void {
    const outputState = this.currentOutputState;
    outputState.vehicles[VehicleIndex] = {...outputState.vehicles[VehicleIndex],...vehicle};

    this._output.next(outputState)
  }

  updateServicePlan(plan: ServicePlan): void {
    const outputState = this.currentOutputState;
    const updatedServicePlan: ServicePlan = {
      ...this.currentOutputState.servicePlan,
      ...plan
    };

    outputState.servicePlan = updatedServicePlan;
    this._output.next(outputState)
  }

  updateDeviceVehicleState(vehicle: Vehicle): void {
    const outputState = this.currentOutputState;
    const updatedVehicle: Vehicle = {
      ...this.currentDeviceVehicleState,
      ...vehicle
    };

    outputState.DeviceVehicleInfo = updatedVehicle;
    this._output.next(outputState)
  }

  addLicensePlate(plate) {
    const newState: Output = {
      ...this.currentOutputState,
      ...plate,
    };

    this._output.next(newState);
  }

  resetCurrentVehicle(vehicleIndex:number): void {
    const vehicles = this.currentOutputState.vehicles
    vehicles[vehicleIndex] = {key:vehicles[vehicleIndex].key,comboTractorkey:vehicles[vehicleIndex].comboTractorkey};
  }

  async createUserAccount(isNoVehicleFlow: boolean): Promise<string> {
    const accountDetails = {
      ...this.mapModelToApiFormat(),
      AcceptedTos: true
    };

    // Remove vehicles property for flow B.    
    if(isNoVehicleFlow){
      delete accountDetails.vehicles;
    }

    // Account number needs to be stored for two reasons:
    // 1. Account number is displayed to user on success screens
    // 2. If user creates account but gets a 400 (fixable) response from payment endpoint,
    //    account should not be recreated, just hold a ref to previously created acct #
    const accountNumber = this.accountNumber || await this.onboardingAPI.createAccount(accountDetails)

    this.accountNumber = accountNumber

    return accountNumber
  }

  async addVehiclesToTheAccount(): Promise<void> {
    this.addVehicleInProcess = true;
    const vehicleDetails = {
      vehicles: this.mapVehicleModelToApiFormat(),
      AccountNumber: this.currentOutputState.accountNumber,
      ShipmentTypeId: this.currentOutputState.company.ShipmentTypeId,
      attributionId: this.currentOutputState.attributionid,
      tollPlanId: this.currentOutputState.servicePlan.tollPlanId
    }

    await this.onboardingAPI.addVehicle(vehicleDetails);
    this.addVehicleInProcess = false;
  }

   getTollPlanId() {
    return JSON.parse(JSON.stringify(this.currentOutputState))?.servicePlan?.tollPlanId;
  }

  mapModelToApiFormat(): any {
    const model = JSON.parse(JSON.stringify(this.currentOutputState));

    if (model.servicePlan) {
      if (model.oem === "mercedes") {
        model.tollPlanId = 12
      } else {
        model.tollplanid = model.servicePlan.tollPlanId;
      }
      delete model.servicePlan;
    }

    if ('baseServicePlan' in model) {
      delete model.baseServicePlan;
    }

    delete model.vehicleAddMethod;

    //spreadsheet specific vehicle mapping handled in component
    if (this.isSpreadsheetFlow) {
      return model;
    }

    let vehicles = model.vehicles;
    delete model.vehicles;

    let mappedVehicles = [];
    for (var i = vehicles.length - 1; i >= 0; i--) {

      let vehicle = vehicles[i];

      let towingData = vehicle?.towing;

      delete vehicle.towing;
      delete vehicle.isTrailerOwned;

      if (vehicle.vin == '') {
        delete vehicle.vin
      }

      if (towingData == undefined) {
        mappedVehicles.push(vehicle);
        continue;
      }

      let mappedVehicle = { ...vehicle };

      mappedVehicle['licenseplate'] = towingData.licenseplate;
      mappedVehicle['axles'] = towingData.axles;
      mappedVehicle['ownership'] = towingData.ownership;

      mappedVehicles.push(mappedVehicle);

    }

    model.vehicles = mappedVehicles;

    return model;
  }

  mapVehicleModelToApiFormat(): any {
    let model = JSON.parse(JSON.stringify(this.currentOutputState.vehicles));
    let mappedVehicles = [];

    for (var i = model.length - 1; i >= 0; i--) {

      let vehicle = model[i];

      let towingData = vehicle?.towing;

      delete vehicle.towing;
      delete vehicle.isTrailerOwned;

      if (vehicle.vin == '') {
        delete vehicle.vin
      }

      if (towingData == undefined) {
        mappedVehicles.push(vehicle);
        continue;
      }

      let mappedVehicle = { ...vehicle };

      mappedVehicle['licenseplate'] = towingData.licenseplate;
      mappedVehicle['axles'] = towingData.axles;
      mappedVehicle['ownership'] = towingData.ownership;

      mappedVehicles.push(mappedVehicle);

    }

    model = mappedVehicles;

    return model;
  }

  // Only meant to be used by PartialDataResolver on partial ingest application load
  getPartialData(UUID: string): Observable<Output> {
    this.isPartialDataIngestFlow = true

    return this.onboardingAPI.getPartialData(UUID)
  }

  getStateProvinceListBasedOnCountry(country: string): {[key: string]: string} {
    switch (country) {
        case "USA" : {
            return this.USAStates;
        }
        case "MEX" : {
          return this.MEXStates;
        }
        case "CAN" : {
          return this.CANProvinces
        }
        default : {
          break;
        }
    }
  }

  get USAStates(): { [key: string]: string } {
    return (
      {
        'AL': 'Alabama',
        'AK': 'Alaska',
        'AZ': 'Arizona',
        'AR': 'Arkansas',
        'CA': 'California',
        'CO': 'Colorado',
        'CT': 'Connecticut',
        'DE': 'Delaware',
        'DC': 'District Of Columbia',
        'FL': 'Florida',
        'GA': 'Georgia',
        'HI': 'Hawaii',
        'ID': 'Idaho',
        'IL': 'Illinois',
        'IN': 'Indiana',
        'IA': 'Iowa',
        'KS': 'Kansas',
        'KY': 'Kentucky',
        'LA': 'Louisiana',
        'ME': 'Maine',
        'MD': 'Maryland',
        'MA': 'Massachusetts',
        'MI': 'Michigan',
        'MN': 'Minnesota',
        'MS': 'Mississippi',
        'MO': 'Missouri',
        'MT': 'Montana',
        'NE': 'Nebraska',
        'NV': 'Nevada',
        'NH': 'New Hampshire',
        'NJ': 'New Jersey',
        'NM': 'New Mexico',
        'NY': 'New York',
        'NC': 'North Carolina',
        'ND': 'North Dakota',
        'OH': 'Ohio',
        'OK': 'Oklahoma',
        'OR': 'Oregon',
        'PA': 'Pennsylvania',
        'PR': 'Puerto Rico',
        'RI': 'Rhode Island',
        'SC': 'South Carolina',
        'SD': 'South Dakota',
        'TN': 'Tennessee',
        'TX': 'Texas',
        'UT': 'Utah',
        'VT': 'Vermont',
        'VA': 'Virginia',
        'WA': 'Washington',
        'WV': 'West Virginia',
        'WI': 'Wisconsin',
        'WY': 'Wyoming'
      }
    )
  }

  get MEXStates(): { [key: string]: string } {
    return (
      {
        'AG': 'Aguascalientes',
        'BC': 'Baja California',
        'BS': 'Baja California Sur',
        'CM': 'Campeche',
        'CS': 'Chiapas',
        'CH': 'Chihuahua',
        'CO': 'Coahuila',
        'CL': 'Colima',
        'DF': 'Mexico City',
        'DG': 'Durango',
        'GT': 'Guanajuato',
        'GR': 'Guerrero',
        'HG': 'Hidalgo',
        'JA': 'Jalisco',
        'EM': 'Mexico',
        'MI': 'Michoacan',
        'MO': 'Morelos',
        'NA': 'Nayarit',
        'NL': 'Nuevo Leon',
        'OA': 'Oaxaca',
        'PU': 'Puebla',
        'QT': 'Quer&eacute;taro',
        'QR': 'Quintana Roo',
        'SL': 'San Luis Potosi',
        'SI': 'Sinaloa',
        'SO': 'Sonora',
        'TB': 'Tabasco',
        'TM': 'Tamaulipas',
        'TL': 'Tlaxcala',
        'VE': 'Veracruz',
        'YU': 'Yucatan',
        'ZA': 'Zacatecas'
      }
    );

  }

  get CANProvinces(): { [key: string]: string } {
    return (
      {
        'AB' : 'Alberta',
        'BC' : 'British Columbia',
        'MB' : 'Manitoba',
        'NB' : 'New Brunswick',
        'NL' : 'Newfoundland and Labrador',
        'NS' : 'Nova Scotia',
        'ON' : 'Ontario',
        'PE' : 'Prince Edward Island',
        'QC' : 'Quebec',
        'SK' : 'Saskatchewan',
        'NT' : 'Northwest Territories',
        'NU' : 'Nunavut',
        'YT' : 'Yukon',
    });
  }

  setLicensePlateType(licensePlateType: LicensePlateType) {
    this.licensePlateTypeStr = licensePlateType;
  }

  get licensePlateType(): LicensePlateType {
    return this.licensePlateTypeStr;
  }

  setUpdatePayiumCheckbox(isUpdatePayvium: boolean) {
    this.isUpdatePayvium = isUpdatePayvium;
  }

  get updatePayiumCheckbox(): boolean {
    return this.isUpdatePayvium;
  }
  
  setBillingAddressObj(address: BillingAddress) {
    this.billingAdress = address;
  }

  get billingAddressObj(): BillingAddress {
    return this.billingAdress;
  }
}

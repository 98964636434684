import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

export class CustomPreloadingStrategy implements PreloadingStrategy {
  private preloadModules = false;

  setPreload(flag: boolean): void {
    this.preloadModules = flag;
  }

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (this.preloadModules) {
      return load();
    } else {
      return of(null);
    }
  }
}

import {Inject, Injectable} from '@angular/core';
import {DefaultScriptStore} from '../models/config';
import {DOCUMENT} from '@angular/common';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ScriptService {

  private scripts: any = {};

  paytraceLink: string;

  constructor(@Inject(DOCUMENT) private document) {    
    if (environment.envName == "Prod") {
      this.paytraceLink = "https://protect.paytrace.com/js/protect.min.js";
    } else {
      this.paytraceLink = "https://protect.sandbox.paytrace.com/js/protect.min.js"
    }

    DefaultScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: this.paytraceLink,
        requiresReload: script.requiresReload
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve) => {
      if (!this.scripts[name].loaded || this.scripts[name].requiresReload) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        script.onload = () => {
          this.scripts[name].loaded = true;
          resolve({script: name, loaded: true, status: 'Loaded', requiresReload: this.scripts[name].requiresReload});
        };
        script.onerror = () =>
          resolve({script: name, loaded: false, status: 'Loaded', requiresReload: this.scripts[name].requiresReload});
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({script: name, loaded: true, status: 'Already Loaded', requiresReload: this.scripts[name].requiresReload});
      }
    });
  }

}




import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Config, Page } from 'src/app/data/models/config';
import { ConfigService } from 'src/app/data/services/config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-success-popup',
  templateUrl: './success-popup.component.html',
  styleUrls: ['./success-popup.component.scss']
})
export class SuccessPopupComponent implements OnInit{
  isVendorOwnerOp: boolean = false;
  isVendorITM: boolean = false;
  isVendorEFS: boolean = false;
  isVendorMercer: boolean = false;
  OnboardingLink: string;
  vendorName: string;
  enviornmentName: string;
  itmRedirectLink: string = "https://onboarding.payviam.com/#/activate";
  @Input() isDisplaySuccessPopup: boolean;

  constructor(public configService: ConfigService) { }

  ngOnInit() {
    this.isVendorOwnerOp = this.configService.config.vendorId === "owner-op";
    this.isVendorITM = this.configService.isITMVendor();
    this.isVendorEFS = this.configService.isEFSVendor();
    this.isVendorMercer = this.configService.config.vendorId === "mercer";
    this.OnboardingLink = environment?.onboardingURL;
    this.enviornmentName = environment?.envName;
    this.vendorName = "/"+this.configService.config.vendorId+"/contact/info?saved=";

  }

  closeModal() {
    this.configService.partialDataSuccessPopup = false;
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDownHandler(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      this.closeModal();
    }
  }

  get config(): Config {
    return this.configService.config;
  }

  get currentScreen(): Page {
    return this.configService.currentPage;
  }
}
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { OutputService } from '../services/output.service';

@Injectable({
  providedIn: 'root'
})
export class PartialDataResolver implements Resolve<any> {

  constructor( public outputService: OutputService) { }

  async resolve(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Promise<any> {

  	

  	if(route.queryParams.UUID != undefined){

      let model = await this.outputService.getPartialData(route.queryParams.UUID).toPromise();

      this.outputService.updateState(model);

       
      return this.outputService.currentOutputState;
 
  	}
    

    return null; 
   
  }
}



import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';

import { AppRoutingModule } from './modules/app-routing.module';
import { MaterialModule } from './modules/material.module';
import { HttpClientModule } from '@angular/common/http';
import { AppConfig } from './app.config';

import { environment } from 'src/environments/environment';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { MockDb } from './data/mock-db';

import { AppComponent } from './app.component';
import { MainWrapperComponent } from './components/main-wrapper/main-wrapper.component';
import { ConfirmationComponent } from './components/utility/confirmation/confirmation.component';
import {init as SentryInit, routingInstrumentation as SentryRoutingInstrumentation, createErrorHandler as SentryCreateErrorHandler } from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { SuccessComponent } from './screens/success/success.component';
import { MdePopoverModule } from '@material-extended/mde';
import { MatCardModule } from '@angular/material/card';
import { WelcomeComponent } from './screens/welcome/welcome.component';
import { PageNotFoundComponent } from './screens/page-not-found/page-not-found.component';
import { WelcomeItmComponent } from './screens/welcome/welcome-itm/welcome-itm.component';
import { UrlSerializer } from '@angular/router';
import { LowerCaseUrlSerializer } from './data/services/url-serializer.service';
import { StepperComponent } from './components/stepper/stepper.component';
import { MatStepperModule } from '@angular/material/stepper';
import { SuccessPopupComponent } from './components/utility/success-popup/success-popup.component';
import { ErrorPopupComponent } from './components/utility/error-popup/error-popup.component';
import { LaunchDarklyService } from './data/services/launch-darkly/launch-darkly.service';
import { PromoBannerComponent } from './components/utility/promo-banner/promo-banner.component';
import { BlankLoadingPageComponent } from './components/utility/blank-loading-page/blank-loading-page.component';
import { WelcomeOtherVendorComponent } from './screens/welcome/welcome-otherVendor/welcome-otherVendor.component';
import { FinishVehiclesComponent } from './components/utility/finish-vehicles/finish-vehicles.component';
import { SimpleLoadingSpinnerComponent } from './components/utility/simple-loading-spinner/simple-loading-spinner.component';
import { ContinueCurrentStepComponent } from './components/utility/continue-current-step/continue-current-step.component';
import { SharedModule } from './modules/sharedmodule';
import { CustomPreloadingStrategy } from './data/services/custom.preloadStrategy';

if (environment.useSentry) {
  SentryInit({
    dsn: environment.sentryDSN,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost', 'https://yourserver.io/api'],
        routingInstrumentation: SentryRoutingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: environment.envName,
  });
}

// Config is for HammerJS to enable vertical scroll with swiping cards in the Coverage Page
declare const Hammer: any;
class HammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    return new Hammer(element, {
      touchAction: "pan-y",
    });
  }
}

@NgModule({
  declarations: [
    AppComponent,
    MainWrapperComponent,
    ConfirmationComponent,
    SuccessComponent,
    WelcomeComponent,
    PageNotFoundComponent,
    WelcomeItmComponent,
    StepperComponent,
	  SuccessPopupComponent,
    ErrorPopupComponent,
    PromoBannerComponent,
    BlankLoadingPageComponent,
    FinishVehiclesComponent,
    WelcomeOtherVendorComponent,
    SimpleLoadingSpinnerComponent,
    ContinueCurrentStepComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    RecaptchaModule,
    HammerModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatProgressBarModule,
    HttpClientModule,
    MatStepperModule,
    environment.useMockDB
    ? HttpClientInMemoryWebApiModule.forRoot(MockDb, {
        passThruUnknownUrl: true,
      })
    : [],
  BrowserAnimationsModule,
  AppRoutingModule,
  MdePopoverModule,
  MatCardModule
],
providers: [
  LaunchDarklyService,
  AppConfig,
  CustomPreloadingStrategy,
  {
    provide: ErrorHandler,
    useValue: SentryCreateErrorHandler({
      showDialog: true,
    }),
  },
  {
    provide: UrlSerializer,
    useClass: LowerCaseUrlSerializer
  },
  { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig }
],
bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    // Comment to resolve SQ defect
  }
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ScriptService} from '../services/script.service';


@Injectable({
  providedIn: 'root'
})
export class ProtectJSResolve implements Resolve<any> {

  constructor(private scriptService: ScriptService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this.scriptService.load('protectJS').then();
  }
}

import { ServicePlan } from './config';

export interface Output {
  oemId?: number;
  oem?: string;
  order?: Order;
  transponderId?: string;
  vehicleAddMethod?: 'manual' | 'spreadsheet';
  vehicles?: Vehicle[];
  DeviceVehicleInfo?: Vehicle;
  attributionid?: number;
  contacts?: Contact[];
  company?: Company;
  accountNumber?: string;
  addByPassval?: number | string;
  addOnIds?: [number];
  AcceptedTosORDP?: boolean;
  RefundReceiverName?: string;
  SelectedVehicleCount?: number;


  /*
  servicePlan will store the service plan that the user will end up enrolling in
  If the user opts for the bypass option then the bypass plan will be stored in
  service plan while the base service plan (w/o bypass) will be stored in baseServicePlan
  */
  servicePlan?: ServicePlan;
  baseServicePlan?: ServicePlan;
  ordpticket?: OrdpTicket;
}

export interface ShippingAddress {
  street?: string;
  street2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
}

export interface Company {
  name?: string;
  address?: ShippingAddress;
  dotnumber?: string;
  salesPersonId?: string;
  ShipmentTypeId?: number;
}

export interface Vehicle {
  key?: string;
  type?: VehicleType | TrailerType;
  towvehicletype?: VehicleType;
  id?: string;
  tires?: number;
  weight?: number;
  make?: string;
  model?: string;
  year?:  string;
  licenseplate?: Registration;
  vin?: string;
  axles?: number;
  otheraxles?: number;
  ownership?: Ownership;
  trailers_greater_than_28_and_a_half_feet?: number;
  length?: number;
  leaseExpirationDate?: string;
  pullsTandemTrailer?: boolean;
  standardTrailerConfig?: StandardTrailerConfig;
  isTempFlag?: boolean;
  licensePlateType?: string;
  transponderNumber?: string;
  isTrailerOwned?: boolean;
  addMoreState?: string;
  addMoreValue?: string;
  comboTractorkey?: string;
}

export interface OrdpTicket {
  existingORDPticketcount?: number;
  MultipleTicketCount?: number;
}

export enum StandardTrailerConfig {
  UNDER = 'UNDER-48FT',
  BETWEEN = 'BETWEEN-4853FT',
  OTHER = 'OTHER',
}

export enum VehicleType {
  BUS = 'BUS',
  BOXTRUCK = 'BOX',
  VAN = 'VAN',
  PICKUPTRAILER = 'PUT',
  TRACTORTRAILER = 'TTP',
  TRAILER = 'TRAILER',
  RV = 'RV',
  MINIBUSLIMO = 'MBUS',
  AUTOMOBILE = 'PAS'
}

export enum TractorType {
  TRACTORTRAILER = 'TTP',
  TRAILER = 'TRAILER',
}

export enum TrailerType {
  FIFTHWHEEL = 'FWT',
  STANDARD = 'STT',
  TRACTORTRAILER = 'TTT',
}

export interface Contact {
  FirstName: string;
  LastName: string;
  email?: string;
  phone?: string;
  phoneSmsOptIn?: boolean;
}

export interface Order {
  total: number;
}

export interface Registration {
  country: string;
  state: string;
  number?: string;
  plateType?: string;
  isUpdatePayvium?: boolean;
}

export enum Ownership {
  OWN = 'owned',
  LEASE = 'leased',
}

export type LicensePlateType = 'Permanent' | 'Temp' | 'None/Unsure';
